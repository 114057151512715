<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h1
            class="main-logo"
            @click="$router.push({name: 'home'}).catch(() => {})"
          >
            <img
              :src="Logo"
              class="img-fluid"
              alt="logo"
            >
          </h1>
        </b-link>

        <b-card-title class="mb-1">
          Congratulations! you have verify your email address
        </b-card-title>
        <b-card-text class="mb-2">
          You can login to GoTo-Source
        </b-card-text>

        <b-button
          variant="outline-success"
          type="submit"
          block
          @click="$router.push({name: 'login'}).catch(() => {})"
        >
          Login
        </b-button>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Logo from '../../assets/images/logo/logo.png'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      Logo,
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      reset: {
        email: '',
      },
    }
  },
  mounted() {
    const query = this.$route?.query
    const { code } = query
    this.sendVerificationCode(code)
  },
  methods: {
    addRememberMe() {
      this.login.remember_me = !this.login.remember_me
    },
    sendVerificationCode(code) {
      this.$store.dispatch('auth/verifyEmail', code)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
